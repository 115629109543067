import {
    ConversationSpec,
    ConversationItem,
    ConversationItemType,
    ReceiveOption,
    ReceiveText,
    Say,
    For,
    Jump,
    Options,
    Option,
    Block,
    SugaredSpec,
    SugaredItem,
    isSugaredSay,
    isSugaredFor,
    isSugaredJump,
    isSugaredReceiveOption,
    isSugaredReceiveText,
    SugaredSay,
    SugaredReceiveText,
    SugaredReceiveOption,
    SugaredFor,
    SugaredJump,
    RECEIVE,
    FOR,
    CardType,
    CardCases,
    CardCase,
    Card,
    CardSection,
    BlockBuilderModel,
    MessageCard,
    QuestionCard,
    ContactLookupCard,
    OptionsCard,
    MessageCardBranched,
    QuestionCardBranched,
    OptionsCardBranched,
    JumpCard,
    UnknownCard,
    BranchCard,
    BaseCard,
    Cases,
    MessagePattern,
    QuestionPattern,
    ListPattern,
    BranchPattern,
    JumpPattern,
    BasePattern,
    Pattern,
    isBranchedCard,
    JumpCardBranched,
    ExternalPattern,
    ExternalCard,
    ExternalCardBranched,
    isScorecardCard,
    ScorecardCard,
    OptionLabels,
    TYPE_CONTACT_LOOKUP,
    TYPE_REVIEW_LINK_BUTTON,
    ReviewLinkButtonCard,
    REVIEW_LINK_INPUT,
    LOOKUP_EMAIL,
} from '@/entities/conversation'
import { map as mapObject } from '@/utils/object'
import { flatten1, range } from '@/utils/array'

// ---------- Block Builder mappings ----------

/**
 * I've chosen to transform the spec into a data structure that is closer to the UI
 * in Drake's designs, in order to keep the Vue components understandable and not
 * tightly coupled to the spec storage format. Working directly with the storage
 * format in the UI would be a nightmare.
 *
 * We will also only write according to these patterns, so can assume the spec
 * will be structured this way and won't change, i.e. it will only be
 * blocks containing lists of these patterns (not much nesting etc).
 *
 * The main test should assert that:
 * - specToViewModel(viewModelToSpec(v)) = v
 * - viewModelToSpec(specToViewModel(s)) = s
 *
 * Writing to the spec from the UI elements is easy, but reading and determining what
 * view type to display is a bit harder.
 *
 * My first attempt was with a pattern-matching, structural heuristic approach.
 * This used prededence rules, since the grammar is ambiguous, and resulted
 * in code that was hard to reason about (and I wasn't confident I could make it correct)
 *
 * This approach uses annotations on spec items that denote when a card starts,
 * the name of the card, and its length. The mapper then reads.
 *
 * Another approach considered was to have this annotation in an outside descriptor
 * format, but I thought it was ok to include the information inline rather than
 * maintaining fiddly outside indexes. It does change the format structure, but
 * it's a superset only to be read by the block builder, not the survey interpreter.
 *
 * We do introduce one keyword that is significant to the interpreter: 'preventFallthrough'
 * which is used to denote 'flows', outside the main sequence. They can only be jumped
 * to / from, it won't fall through to them or from them.
 */

export function specToBlockBuilderModel(
    spec: ConversationSpec
): BlockBuilderModel {
    return spec.map(blockToCardSection)
}

// We only pattern match against the items in the top level of the block
export function blockToCardSection(block: Block): CardSection {
    return {
        name: block.label,
        cards: blockItemsToCards(block.items),
        flow: block.preventFallthrough,
    }
}

function patternToCard(
    cardType: CardType,
    itemType: ConversationItemType,
    data: ConversationItem[],
    optionLabels?: OptionLabels
): Card {
    // construct branched
    if (itemType === 'For') {
        const [branch] = data as BranchPattern<BasePattern>
        const card = {
            type: cardType,
            match: branch.match,
            cases: itemCasesToCardCases(branch.cases),
        } as BranchCard
        if (branch.scorecardId) {
            (card as ScorecardCard).scorecardId = branch.scorecardId
        }
        return card
    }

    let say: Say
    let receive: ReceiveOption | ReceiveText
    let jumps: For
    let jump: Jump

    // construct base card
    switch (cardType) {
        case 'message':
            [say] = data as MessagePattern
            return {
                type: cardType,
                say: say.value,
            } as MessageCard

        case TYPE_CONTACT_LOOKUP:
            [say, receive] = data as QuestionPattern
            return {
                type: cardType,
                say: say.value,
                param: receive.param,
                contactType: receive.extraOption,
            } as ContactLookupCard

        case TYPE_REVIEW_LINK_BUTTON:
            [say, receive] = data as QuestionPattern
            return {
                type: cardType,
                say: say.value,
                param: receive.param,
                linkType: receive.extraOption,
                title: receive.title,
                btnTitle: receive.btnTitle,
                confirmationMessage: receive.confirmationMessage,
            } as ReviewLinkButtonCard

        case 'question':
            [say, receive] = data as QuestionPattern
            return {
                type: cardType,
                say: say.value,
                param: receive?.param,
                aiConversationalMode: say.aiConversationalMode ?? false,
                aiQuestionCount: say.aiQuestionCount ?? 1,
            } as QuestionCard

        case 'list':
        case 'csat':
        case 'ces':
        case 'fivestar':
        case 'nps':
            [say, receive, jumps] = data as ListPattern
            return {
                type: cardType,
                say: say.value,
                param: receive.param,
                optionLabels: optionLabels,
                options: receive.options.map((opt) => {
                    const res: Option & { jump?: string } = { ...opt }
                    const jump = getJumpLabelForKey(jumps.cases, opt.key)
                    if (jump) {
                        res.jump = jump
                    }
                    return res
                }),
                randomizeOrder: receive.randomizeOrder ?? false,
                multiSelect: receive.multiSelect ?? false,
            } as OptionsCard

        case 'jump':
            [jump] = data as JumpPattern
            return {
                type: cardType,
                jump: jump.label,
            } as JumpCard

        case 'external':
            [say, receive] = data as ExternalPattern
            return {
                type: cardType,
                say: say.value,
                link: receive.link,
            } as ExternalCard

        default:
            return {
                type: 'unknown',
            } as UnknownCard
    }
}

// convert from our storage format to a 'card' form convenient for editing in our block UI
export function blockItemsToCards(items: ConversationItem[]): Card[] {
    const cards: Card[] = []
    const frontier = [...items]

    while (frontier[0]) {
        const { type, cardType, cardLength, optionLabels } = frontier[0]

        if (cardType && cardLength) {
            const data = frontier.splice(0, cardLength)
            cards.push(patternToCard(cardType, type, data, optionLabels))
        } else {
            frontier.splice(0, 1) // <- prevents an infinite loop
        }
    }

    return cards
}

function getJumpLabelForKey(
    cases: { [name: string]: ConversationItem[] },
    key: string
) {
    const items = cases[key]
    if (items) {
        const jump = items.find(({ type }) => type === 'Jump')
        if (jump) {
            return (jump as Jump).label
        }
    }
}

function itemCasesToCardCases(cases: Cases<ConversationItem[]>) {
    const cardCases: CardCases<BaseCard> = []

    for (const key in cases) {
        if (cases.hasOwnProperty(key)) {
            const cards = blockItemsToCards(cases[key]) as BaseCard[]

            if (cards[0]) {
                cardCases.push({ key, card: cards[0] }) // branch can only have one card
            }
        }
    }
    return cardCases.sort(sortByKeyOrDefault) // keep cases in alphabetical order by key, with default last
}

function sortByKeyOrDefault(a, b): number {
    const def = 'default'
    const aKey = a.key.toLowerCase()
    const bKey = b.key.toLowerCase()
    const aDef = aKey === def
    const bDef = bKey === def
    if (bDef && !aDef) {
        return -1
    }
    if (aDef && !bDef) {
        return 1
    }
    return aKey < bKey ? -1 : aKey > bKey ? 1 : 0
}

export function blockBuilderModelToSpec(
    cardSections: BlockBuilderModel
): ConversationSpec {
    const spec: ConversationSpec = []

    // each section in the master is a normal block
    // they go in sequence and fall through to each other
    // each flow is a special block that has the property 'preventFallthrough = true'
    // it is treated by the interpreter as 'outside' the normal sequence,
    // meaning you can jump to it, and back to a block from it, but can't fall through to it, or from it
    for (const section of cardSections) {
        const block = sectionToBlock(section)
        if (section.flow) {
            block.preventFallthrough = true
        }
        spec.push(block)
    }

    return spec
}

// Map card back to conversation items before storage
export function cardToConversationItems(card: Card): Pattern | [] {
    if (isBranchedCard(card)) {
        const pattern = [
            {
                cardType: card.type,
                cardLength: 1,
                type: 'For',
                match: card.match,
                cases: cardCasesToItemCases(card.cases),
            },
        ] as BranchPattern<BasePattern>
        if (isScorecardCard(card)) {
            pattern[0].scorecardId = card.scorecardId
        }
        return pattern
    }

    switch (card.type) {
        case 'message': {
            return [
                {
                    cardType: card.type,
                    cardLength: 1,
                    type: 'Say',
                    value: card.say.trim(),
                },
            ] as MessagePattern
        }

        case TYPE_CONTACT_LOOKUP:
            return [
                {
                    cardType: card.type,
                    cardLength: 2,
                    type: 'Say',
                    value: card.say.trim(),
                    extraOption: card.contactType ?? LOOKUP_EMAIL, //for input validation
                },
                {
                    type: 'ReceiveText',
                    param: ensureNoSuffix(card.param.trim()),
                    extraOption: card.contactType ?? LOOKUP_EMAIL, //for input validation
                },
            ] as QuestionPattern

        case TYPE_REVIEW_LINK_BUTTON:
            return [
                {
                    cardType: card.type,
                    cardLength: 2,
                    type: 'Say',
                    value: card.say.trim(),
                    extraOption: card.linkType ?? REVIEW_LINK_INPUT,
                },
                {
                    type: 'ReceiveText',
                    param: ensureNoSuffix(card.param.trim()),
                    link: card.say.trim(),
                    extraOption: card.linkType ?? REVIEW_LINK_INPUT,
                    title: card.title,
                    btnTitle: card.btnTitle,
                    confirmationMessage: card.confirmationMessage,
                },
            ] as QuestionPattern

        case 'question': {
            return [
                {
                    cardType: card.type,
                    cardLength: 2,
                    type: 'Say',
                    value: card.say.trim(),
                    aiConversationalMode: card.aiConversationalMode ?? false,
                    aiQuestionCount: card.aiQuestionCount ?? 1,
                },
                {
                    type: 'ReceiveText',
                    param: ensureNoSuffix(card.param.trim()),
                },
            ] as QuestionPattern
        }

        case 'list':
        case 'csat':
        case 'ces':
        case 'nps': {
            const param = ![
                'list',
                'scorecard',
                'scorecard_promoter',
                'scorecard_default',
            ].includes(card.type)
                ? ensureSuffix(card.param, `_${card.type}`)
                : ensureNoSuffix(card.param)
            return [
                {
                    cardType: card.type,
                    cardLength: 3,
                    type: 'Say',
                    value: card.say.trim(),
                    optionLabels: card.optionLabels,
                },
                {
                    type: 'ReceiveOption',
                    param: param.trim(),
                    options: card.options.map(
                        ({
                            key,
                            value,
                            badgeId,
                            ogKey,
                            scorecardTopicName,
                        }) => ({
                            key: key.trim(),
                            value: value.trim(),
                            badgeId,
                            ogKey,
                            scorecardTopicName,
                        })
                    ),
                    randomizeOrder: card.randomizeOrder ?? false,
                    multiSelect: card.multiSelect ?? false,
                },
                {
                    type: 'For',
                    match: param.trim(),
                    cases: collectJumps(card.options),
                },
            ] as ListPattern
        }

        case 'jump': {
            return [
                {
                    cardType: card.type,
                    cardLength: 1,
                    label: card.jump.trim(),
                    type: 'Jump',
                },
            ] as JumpPattern
        }

        case 'external': {
            return [
                {
                    cardType: card.type,
                    cardLength: 2,
                    type: 'Say',
                    value: card.say,
                },
                {
                    type: 'ReceiveOption',
                    param: 'external', // will not be used to store anything
                    link: card.link,
                    options: [
                        { key: 'yes', value: 'Find a time' },
                        { key: 'no', value: 'No thanks' },
                    ],
                },
            ] as ExternalPattern
        }

        default: {
            return []
        }
    }
}

function cardCasesToItemCases(cases: Array<{ key: string; card: Card }>) {
    const itemCases: Cases<ConversationItem[]> = {}
    for (const { key, card } of cases) {
        const items = cardToConversationItems(card)
        if (items && items.length > 0) {
            itemCases[key] = items
        }
    }
    return itemCases
}

function collectJumps(options: Array<Option & { jump?: string }>) {
    const jumps: { [name: string]: [Jump] } = {} // collect jumps for options that have them
    for (const { key, jump } of options) {
        if (jump) {
            jumps[key] = [{ type: 'Jump', label: jump.trim() }]
        }
    }
    return jumps
}

export function ensureSuffix(str: string, suffix: string) {
    return str.replace(new RegExp(suffix + '$'), '') + suffix
}

export function ensureNoSuffix(str: string) {
    return str.replace(new RegExp('(_csat|_ces|_nps)$'), '')
}

export function sectionToBlock(
    section: CardSection,
    extra: Partial<Block> = {}
): Block {
    const specItemGroups: ConversationItem[][] = section.cards
        .map(cardToConversationItems)
        .filter((items) => items && items.length > 0)

    return {
        type: 'Block',
        label: section.name,
        items: flatten1(specItemGroups),
        ...extra,
    }
}

// Blank card initializer
export function blankCard(
    type: CardType,
    branched?: boolean,
    paramsCount = 0,
    topicKeyUsed = 1
): Card {
    if (branched) {
        // todo scorecard3 remove
        if (type === 'scorecard') {
            return {
                type: 'list',
                match: 'rating',
                cases: [
                    {
                        key: 'promoter',
                        card: blankCard(
                            'scorecard_promoter',
                            false,
                            paramsCount
                        ),
                    },
                    {
                        key: 'default',
                        card: blankCard(
                            'scorecard_default',
                            false,
                            paramsCount
                        ),
                    },
                ],
            } as BranchCard
        } else if (type === 'scorecardv3') {
            return {
                type: 'list',
                match: 'rating',
                isScorecard: true,
                scorecardId: 0,
                cases: [
                    {
                        key: 'promoter',
                        card: blankCard(
                            'scorecardv3_promoter',
                            false,
                            paramsCount
                        ),
                    },
                    {
                        key: 'default',
                        card: blankCard(
                            'scorecardv3_default',
                            false,
                            paramsCount
                        ),
                    },
                ],
            } as BranchCard
        }
        return {
            type,
            match: '',
            cases: [
                { key: 'default', card: blankCard(type, false, paramsCount) },
            ],
        } as BranchCard
    }

    const param = ''

    switch (type) {
        case 'message':
            return { type, say: '' } as MessageCard
        case 'question':
            return { type, say: '', param } as QuestionCard
        case TYPE_CONTACT_LOOKUP:
            return {
                type,
                say: '',
                param: TYPE_CONTACT_LOOKUP, // default question key
                contactType: LOOKUP_EMAIL, // default to email
            } as ContactLookupCard
        case TYPE_REVIEW_LINK_BUTTON:
            return {
                type,
                say: '',
                param: TYPE_REVIEW_LINK_BUTTON, // default question key
                linkType: REVIEW_LINK_INPUT, // default to email
            } as ReviewLinkButtonCard
        case 'external':
            return { type, say: '', link: '' } as ExternalCard
        case 'list':
            return {
                type,
                say: '',
                param: '',
                options: defaultListOptions(),
            } as OptionsCard
        // todo scorecard3 remove
        case 'scorecard_promoter':
            return {
                type: 'list',
                say: 'Thanks! Would you like to share a compliment directly with the team that helped you?',
                param: 'scorecard',
                options: defaultScorecardPromoterOptions(),
            } as OptionsCard
        case 'scorecard_default':
            return {
                type: 'list',
                say: 'From your experience, what should our team focus on improving?',
                param: 'scorecard',
                options: defaultScorecardDefaultOptions(),
            } as OptionsCard
        case 'scorecardv3_promoter':
            return {
                type: 'list',
                say: 'Thanks! Would you like to share a compliment directly with the team that helped you?',
                param: 'scorecard',
                options: [],
            } as OptionsCard
        case 'scorecardv3_default':
            return {
                type: 'list',
                say: 'From your experience, what should our team focus on improving?',
                param: 'scorecard',
                options: [],
            } as OptionsCard
        case 'csat':
            return {
                type,
                say: '',
                param,
                options: defaultCsatOptions(),
            } as OptionsCard
        case 'ces':
            return {
                type,
                say: '',
                param,
                options: defaultCesOptions(),
            } as OptionsCard
        case 'nps':
            return {
                type,
                say: '',
                param,
                options: defaultNpsOptions(),
            } as OptionsCard
        case 'jump':
            return { type, jump: '' } as JumpCard
        case 'unknown':
        default:
            return { type } as UnknownCard
    }
}

export function blankSection(name = ''): CardSection {
    return {
        name,
        cards: [blankCard('unknown')],
    }
}

function defaultListOptions(): Options {
    return [{ key: 'option1', value: 'option1' }]
}

function defaultScorecardPromoterOptions(): Options {
    return [
        {
            key: 'Communication',
            value: 'Great Communication',
            badgeId: 'communication',
        },
        { key: 'Skill', value: 'Skilled Service', badgeId: 'skill' },
        {
            key: 'Friendliness',
            value: 'Super Friendly',
            badgeId: 'friendliness',
        },
        {
            key: 'Knowledge',
            value: 'Wealth of Knowledge',
            badgeId: 'knowledge',
        },
        { key: 'Efficiency', value: 'Well Organised', badgeId: 'efficiency' },
        { key: 'Empathy', value: 'Empathetic', badgeId: 'empathy' },
    ]
}

function defaultScorecardDefaultOptions(): Options {
    return [
        {
            key: 'Communication',
            value: 'Communication',
            badgeId: 'communication',
        },
        { key: 'Skill', value: 'Skill', badgeId: 'skill' },
        { key: 'Friendliness', value: 'Friendliness', badgeId: 'friendliness' },
        { key: 'Knowledge', value: 'Knowledge', badgeId: 'knowledge' },
        { key: 'Efficiency', value: 'Long Wait Times', badgeId: 'efficiency' },
        { key: 'Profession', value: 'Professionalism', badgeId: 'profession' },
    ]
}

export function defaultCsatOptions(): Options {
    return [
        { key: '5', value: 'Very Satisfied' },
        { key: '4', value: 'Satisfied' },
        { key: '3', value: 'Neutral' },
        { key: '2', value: 'Unsatisfied' },
        { key: '1', value: 'Very Unsatisfied' },
    ]
}

function defaultCesOptions(): Options {
    return [
        { key: '5', value: 'Strongly agree' },
        { key: '4', value: 'Agree' },
        { key: '3', value: 'Neither agree nor disagree' },
        { key: '2', value: 'Disagree' },
        { key: '1', value: 'Strongly disagree' },
    ]
}

function defaultNpsOptions(): Options {
    return range(0, 11).map((n) => ({ key: String(n), value: String(n) }))
}

export function baseToBranchCard(card: BaseCard, match: string): BranchCard {
    const branchCard = {
        type: card.type,
        match,
        cases: [{ key: 'default', card }],
    }
    switch (card.type) {
        case 'message':
        case 'readonly':
            return branchCard as MessageCardBranched
        case 'question':
        case TYPE_CONTACT_LOOKUP:
        case TYPE_REVIEW_LINK_BUTTON:
            return branchCard as OptionsCardBranched
        case 'list':
        case 'csat':
        case 'ces':
        case 'nps':
        case 'scorecardv3':
        case 'scorecardv3_promoter':
        case 'scorecardv3_default':
        case 'scorecard': // todo scorecard3
        case 'scorecard_promoter':
        case 'scorecard_default':
            return branchCard as OptionsCardBranched
        case 'jump':
            return branchCard as JumpCardBranched
        case 'external':
            return branchCard as ExternalCardBranched
    }
}

export function branchToBaseCard(card: BranchCard): BaseCard {
    const first = firstCase(card)
    return first
        ? (first.card as BaseCard)
        : (blankCard(card.type, false) as BaseCard)
}

export function firstCase(card: BranchCard): CardCase<BaseCard> | undefined {
    const cases = card.cases as CardCases<BaseCard>
    return cases.find(({ key }) => key === 'default') || card.cases[0]
}

// ---------- YAML mappings ----------

/**
 * The text editor shows a sugared, simpler form that is intended to be
 * lighter and more convenient to read/edit.
 *
 * When loading into the editor, we need to map from our storage format
 * to the sugared form, and vice versa when reading from the editor.
 */

export function fromSugared(sugared: SugaredSpec): ConversationSpec {
    return sugared.map((block) => {
        const key = Object.keys(block)[0]
        return {
            type: 'Block',
            label: key,
            items: block[key].map(fromSugaredItem),
        } as Block
    })
}

function fromSugaredItem(sugared: SugaredItem): ConversationItem {
    const key = Object.keys(sugared)[0]
    const ps = key.split(/\s+/)

    if (isSugaredSay(sugared)) {
        return {
            type: 'Say',
            value: sugared[key],
        } as Say
    }
    if (isSugaredReceiveText(sugared)) {
        return {
            type: 'ReceiveText',
            param: ps.slice(1).join(' '),
        } as ReceiveText
    }
    if (isSugaredReceiveOption(sugared)) {
        return {
            type: 'ReceiveOption',
            param: ps.slice(1).join(' '),
            options: sugared[key].map((option) => {
                const key = Object.keys(option)[0]
                const value = option[key]
                return { key: String(key), value: String(value) }
            }),
        } as ReceiveOption
    }
    if (isSugaredFor(sugared)) {
        return {
            type: 'For',
            match: ps.slice(1).join(' '),
            cases: mapObject<SugaredItem[], ConversationItem[]>(
                sugared[key],
                (items) => {
                    return items.map(fromSugaredItem)
                }
            ),
        } as For
    }
    if (isSugaredJump(sugared)) {
        return {
            type: 'Jump',
            label: sugared[key],
        } as Jump
    }

    throw new Error('Invalid format')
}

export function toSugared(spec: ConversationSpec): SugaredSpec {
    return spec.map((block) => {
        return {
            [`${block.label}`]: block.items.map(toSugaredItem),
        }
    })
}

function toSugaredItem(item: ConversationItem): SugaredItem {
    switch (item.type) {
        case 'Say':
            return { say: item.value } as SugaredSay

        case 'ReceiveText':
            return { [`${RECEIVE} ${item.param}`]: null } as SugaredReceiveText

        case 'ReceiveOption':
            return {
                [`${RECEIVE} ${item.param}`]: item.options.map(
                    ({ key, value }) => ({ [key]: value })
                ),
            } as SugaredReceiveOption

        case 'For':
            return {
                [`${FOR} ${item.match}`]: mapObject(item.cases, (items) =>
                    items.map(toSugaredItem)
                ),
            } as SugaredFor

        case 'Jump':
            return { jump: item.label } as SugaredJump
    }
    throw new Error('Invalid format')
}
