/*
    Given an array,
    deduplicates it by a function that gets a key,
    and a function that decides the precedence
    when two matching items are found.
    The default precedence function just gives preference
    to the later encountered element (i.e elements appearing
    later in the array will win out).
    It doesn't guarantee that sort order will be preserved.
*/

type GetKey<T> = (item: T) => string
type GetPrecedence<T> = (a: T, b: T) => T

function preferLater<T>(a: T, b: T): T {
    return b
}

export function dedupBy<T>(
    xs: T[],
    getKey: GetKey<T>,
    getPrecedence: GetPrecedence<T> = preferLater
): T[] {
    const seen = new Map<string, T>() // Use a Map instead of an object because it has ordered keys
    const deduped: T[] = []

    for (const x of xs) {
        const key = getKey(x)
        const keySeen = seen.get(key)
        seen.set(key, keySeen ? getPrecedence(keySeen, x) : x)
    }

    for (const [key, value] of seen) {
        deduped.push(value)
    }

    return deduped
}

export function flatten<T>(xs: T[][]): T[] {
    return Array.prototype.concat.apply([], xs)
}

export function allUnique(xs: string[] | number[]): boolean {
    const seen = new Set()
    for (const x of xs) {
        if (seen.has(x)) {
            return false
        }
        seen.add(x)
    }
    return true
}

export function nonUniqueIndices(xs: string[] | number[]): number[] {
    const seen = new Map()
    const nonUnique: Set<number> = new Set()

    for (let i = 0; i < xs.length; i++) {
        const x = xs[i]
        if (seen.has(x)) {
            nonUnique.add(seen.get(x))
            nonUnique.add(i)
        }
        seen.set(x, i)
    }
    return Array.from(nonUnique)
}

export function min(xs: number[]): number {
    return Math.min.apply([], xs)
}

export function max(xs: number[]): number {
    return Math.max.apply([], xs)
}

export function extent(xs: number[]): [number, number] {
    return [min(xs), max(xs)]
}

export function zip<A, B, C>(zipper: (a: A, b: B) => C, as: A[], bs: B[]): C[] {
    const length = Math.min(as.length, bs.length)
    const res: C[] = new Array(length)

    for (let i = 0; i < length; i++) {
        res[i] = zipper(as[i], bs[i])
    }
    return res
}

export function sum(xs: number[]): number {
    let res = 0
    for (const x of xs) {
        res += x
    }
    return res
}

export function flatten1<T>(arrays: T[][]): T[] {
    return ([] as T[]).concat.apply([], arrays)
}

export function partition<T>(predicate: (x: T) => boolean, xs: T[]): T[][] {
    const pass: T[] = []
    const fail: T[] = []
    for (const x of xs) {
        if (predicate(x)) {
            pass.push(x)
        } else {
            fail.push(x)
        }
    }
    return [pass, fail]
}

/**
 * Group items by a key extractor function
 */
export interface Grouped<T> {
    key: string
    items: T[]
}

export function groupBy<T>(
    xs: T[],
    getKey: (x: T) => string
): Array<Grouped<T>> {
    const groups = {}
    for (const x of xs) {
        const key = getKey(x)
        if (!groups[key]) {
            groups[key] = []
        }
        groups[key].push(x)
    }

    const items: Array<Grouped<T>> = []
    for (const key in groups) {
        if (groups.hasOwnProperty(key)) {
            items.push({ key, items: groups[key] })
        }
    }

    return items
}

export function range(from: number, to: number) {
    const numbers: number[] = []
    let i = from
    while (i < to) {
        numbers.push(i)
        i++
    }
    return numbers
}

export function inclusiveRange(from: number, to: number) {
    return range(from, to + 1)
}

export function intersection<T>(xs: T[], ys: T[]): T[] {
    return xs.filter((value) => -1 !== ys.indexOf(value))
}

export function arrayToObject(arr) {
    if (Array.isArray(arr)) {
        return arr.reduce(function (accumulator, currentValue) {
            accumulator[currentValue] = currentValue
            return accumulator
        }, {})
    }
    return {}
}

export function filterUnique(arr: string[]) {
    return arr.filter((item, pos) => {
        return arr.indexOf(item) == pos
    })
}

//Durstenfeld shuffle from https://stackoverflow.com/a/12646864
//Yes, that ; is necessary
export function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
}

export function shuffleArray(initialArray) {
    const shuffledArray = initialArray
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[shuffledArray[i], shuffledArray[j]] = [
            shuffledArray[j],
            shuffledArray[i],
        ]
    }

    return shuffledArray
}
