import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36fe18c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "type", "value", "placeholder", "autocomplete", "inputmode"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "input-container",
    onFocusin: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
    onFocusout: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onFocusOut && _ctx.onFocusOut(...args)))
  }, [
    (_ctx.hasFocused && _ctx.hasTitle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["text-title", _ctx.titleClass])
        }, _toDisplayString(_ctx.titleText), 3))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      ref: "input",
      class: _normalizeClass(["input", _ctx.inputClass]),
      disabled: _ctx.disabled,
      type: _ctx.type,
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      autocapitalize: "off",
      autocomplete: _ctx.autocomplete,
      inputmode: _ctx.inputmode,
      onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('focus', $event))),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur', $event))),
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, null, 42, _hoisted_1),
    (_ctx.required)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(["asterisk", _ctx.asteriskClass])
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.asteriskIconClass)
          }, null, 2)
        ], 2))
      : _createCommentVNode("", true)
  ], 32))
}