import { cachedClient as client } from '@/api/clientCached'
import { ajaxClient as uncachedClient } from '@/api/client'
import {
    TeamScoreboardEntity,
    TsGroupRepeatVisitsSummaryEntity,
    TsGroupSummaryEntity,
    TsHasNoScorecardResponse,
    TsNpsSummaryEntity,
    TsTeamGroupEntity,
} from '@/entities/teamscoreboard'
import { GroupStatusMap } from '@/mobile/src/types/insights'
import {
    CoachingInsights,
    TeamUpdatesInsights,
    TeamUpdatesUserData,
} from '@/entities/insights'
import { OfflineCache } from '@/mobile/src/utils/offlinecache'

const offlineCache = new OfflineCache('teamscoreboard')

export async function getTeamScoreboard(
    parentField = '',
    parentGroups: string[] = [],
    selectedField = '',
    selectedGroups: string[] = [],
    selectedRole = '',
    selectedQuestionType = null
) {
    return await offlineCache.cachedGet<TeamScoreboardEntity>(
        client,
        `/ajax/teamscoreboard`,
        -1,
        {
            baseURL: '/',
            params: {
                parentField,
                parentGroups: JSON.stringify(parentGroups),
                selectedField,
                selectedGroups: JSON.stringify(selectedGroups),
                selectedRole: selectedRole,
                selectedQuestionType: selectedQuestionType,
            },
        }
    )
}

export async function getTsTeamGroups(excludeHigherHierarchy = false) {
    return await offlineCache.cachedGet<TsTeamGroupEntity[]>(
        client,
        `/ajax/teamscoreboard/teamgroups`,
        -1,
        {
            baseURL: '/',
            params: {
                excludeHigherHierarchy: excludeHigherHierarchy,
            },
        }
    )
}

export async function getTsTeamParent() {
    return await client.get<TsTeamGroupEntity[]>(
        `/ajax/teamscoreboard/teamparent`,
        {
            baseURL: '/',
        }
    )
}

export async function getTsGroupRepeatVisitsSummary(
    field: string,
    parentField: string,
    filters: object[] = [],
    daterange = '',
    questionType = ''
) {
    return await client.get<TsGroupRepeatVisitsSummaryEntity>(
        `/ajax/insights/repeatvisits`,
        {
            baseURL: '/',
            params: {
                field,
                parentField,
                filters: JSON.stringify(filters),
                daterange,
                questionType,
            },
        }
    )
}

export async function getTsGroupSummary(
    field: string,
    parentField: string,
    filters: object[] = [],
    timeSignature: string,
    role = '',
    daterange = '',
    questionType = '',
    applyActiveFilter = 1,
    fromTeams = 0
) {
    return await offlineCache.cachedGet<TsGroupSummaryEntity>(
        client,
        `/ajax/teamscoreboard/groups`,
        -1,
        {
            baseURL: '/',
            params: {
                field,
                parentField,
                timeSignature,
                filters: JSON.stringify(filters),
                role,
                daterange,
                questionType,
                applyActiveFilter,
                fromTeams,
            },
        }
    )
}

export async function getTsAllGroupSummaries(
    parentField: string,
    filters: object[] = [],
    timeSignature: string,
    role = '',
    daterange = '',
    questionType = '',
    applyActiveFilter = 1,
    fromTeams = 0
) {
    return await offlineCache.cachedGet<TsGroupSummaryEntity[]>(
        client,
        `/ajax/teamscoreboard/all-groups`,
        -1,
        {
            baseURL: '/',
            params: {
                field: 'all',
                parentField,
                timeSignature,
                filters: JSON.stringify(filters),
                role,
                daterange,
                questionType,
                applyActiveFilter,
                fromTeams,
            },
        }
    )
}

export async function getTsSummary(
    field: string,
    groups: string[] = [],
    filters: object[] = [],
    timeSignature: string,
    daterange = '',
    questionType = '',
    withGraph = 0,
    scorecard = null
) {
    return await client.get<TsNpsSummaryEntity>(
        `/ajax/teamscoreboard/npssummary`,
        {
            baseURL: '/',
            params: {
                field,
                timeSignature,
                filters: JSON.stringify(filters),
                groups: JSON.stringify(groups),
                daterange,
                questionType,
                withGraph,
                scorecard: scorecard,
            },
        }
    )
}

export async function getTeamPerformanceReport() {
    return await client.get(`/executive/report/teams`, {
        baseURL: '/',
        responseType: 'blob',
    })
}

export async function getTeamPerformanceReportAsync() {
    const { data: response } = await client.get(
        `/ajax/executive/reportasync/teams`,
        {
            baseURL: '/',
        }
    )
    return response
}

export async function getGeneralNPSReport(params) {
    return await client.get(`/executive/report/general`, {
        baseURL: '/',
        responseType: 'blob',
        params,
    })
}

export async function getGeneralNPSReportAsync(params) {
    const { data: response } = await client.get(
        `/ajax/executive/reportasync/general`,
        {
            baseURL: '/',
            params,
        }
    )
    return response
}

export async function getReportLeaderboardList() {
    return client.get(`/ajax/executive/leaderboards`, {
        baseURL: '/',
    })
}

export async function getInsightsStatuses(
    field: string,
    timeUnit = 'month',
    timeValue = 1
) {
    return client.get<GroupStatusMap>(`/ajax/insights/statuses`, {
        baseURL: '/',
        params: {
            field,
            time_unit: timeUnit,
            time_value: timeValue,
        },
    })
}

export async function getGroupBadges(filters) {
    return client.get(`/ajax/scorecard/badge/group`, {
        baseURL: '/',
        params: {
            filters: JSON.stringify(filters),
        },
    })
}

export async function getCoachingInsights(
    scorecardField,
    timeUnit = 'month',
    timeValue = 1
) {
    return client.get<CoachingInsights>(`/ajax/coaching/insights`, {
        baseURL: '/',
        params: {
            scorecard_field: scorecardField,
            time_unit: timeUnit,
            time_value: timeValue,
        },
    })
}

export async function getTeamUpdatesInsights(
    timeUnit = 'month',
    timeValue = 1
) {
    return uncachedClient.get<TeamUpdatesInsights>(
        `/ajax/insights/teamupdates/teamdata`,
        {
            baseURL: '/',
            params: {
                time_unit: timeUnit,
                time_value: timeValue,
            },
        }
    )
}

export async function getTeamUserInsights(
    userId,
    timeUnit = 'month',
    timeValue = 1
) {
    return uncachedClient.get<TeamUpdatesUserData>(
        `/ajax/insights/teamupdates/userdata`,
        {
            baseURL: '/',
            params: {
                staff_id: userId,
                time_unit: timeUnit,
                time_value: timeValue,
            },
        }
    )
}

export async function getHasNoScorecardResponses(teamScoreboardField = '') {
    return uncachedClient.get<TsHasNoScorecardResponse>(
        `/ajax/response/no-scorecard`,
        {
            baseURL: '/',
            params: {
                teamScoreboardField,
            },
        }
    )
}
