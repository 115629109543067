import client from '@/api/client'
import { OfflineCache } from '@/mobile/src/utils/offlinecache'

const offlineCache = new OfflineCache('huddle')

export async function fetchRosters(): Promise<any> {
    return offlineCache.cachedGet(client, '/huddles/rosters')
}

export async function fetchBaseInfo(): Promise<any> {
    return offlineCache.cachedGet(client, '/huddles/base')
}
