<template>
    <div class="page-wrapper">
        <div id="huddles-container">
            <Toast />
            <div class="column column--left">
                <template v-if="loadingEntirePage">
                    <HuddlesLoading type="left-column" />
                </template>
                <template v-else-if="!showError">
                    <div class="base-display">{{ base }}</div>
                    <img id="company-logo" :src="companyLogo" alt="logo" />
                    <HuddlesOverviewStats
                        :base-goals="baseGoals"
                        :cxscore-as-percentage="true"
                    />
                    <HuddlesPromotion
                        v-if="promotionMessage && promotionMessage.length"
                        :promotion-message="promotionMessage"
                    />
                    <HuddlesCustomerHighlights
                        :highlights="customerHighlights"
                    />
                </template>
                <template v-else>
                    <div class="base-display">-</div>
                </template>
            </div>

            <div class="column column--right">
                <HuddlesLoading
                    v-if="loadingEntirePage"
                    type="date-paginator"
                />
                <HuddlesDatePaginator
                    v-else
                    :loading="loadingHuddleList"
                    :has-next="canPaginate('next')"
                    :has-prev="canPaginate('previous')"
                    :selected-date="selectedDate"
                    @on-paginate="onPaginationChange"
                />

                <template v-if="loadingEntirePage || loadingHuddleList">
                    <HuddlesLoading
                        v-for="card in 3"
                        :key="card"
                        type="huddle-card"
                    />
                </template>
                <template v-else-if="!showError">
                    <div
                        v-for="huddle in selectedDatesHuddles"
                        :key="getFlightKey(huddle)"
                        class="huddles-card huddles-card--details"
                        @click="openHuddleModal(huddle.id)"
                    >
                        <HuddleDetails
                            :huddle="huddle"
                            :base-cxscore-goal="baseGoals.cxscore_goal"
                            :summary-view="true"
                        />
                    </div>
                    <Dialog
                        v-model:visible="showHuddleModal"
                        class="huddle-details-modal"
                        modal
                    >
                        <template #container>
                            <button
                                class="close-modal-button"
                                @click="showHuddleModal = false"
                            >
                                <i class="pi pi-times" />
                            </button>
                            <Carousel
                                :value="getCarouselDetails"
                                :num-visible="1"
                                :num-scroll="1"
                                :show-navigators="false"
                                circular
                            >
                                <template #item="slotProps">
                                    <HuddleDetails
                                        v-if="getSelectedHuddle"
                                        :huddle="slotProps.data"
                                        :base-cxscore-goal="
                                            baseGoals.cxscore_goal
                                        "
                                    />
                                </template>
                            </Carousel>
                        </template>
                    </Dialog>
                </template>
            </div>
        </div>
        <div v-if="showError" id="huddles-error-container">
            <HuddlesError type="page" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, type ComputedRef, type Ref } from 'vue'
import { useStore } from 'vuex'
import { pendoTrackEvent } from '@/utils/pendo'
import { getCDNUrl } from '@/utils/cdn'
import { useToast } from 'primevue/usetoast'
import { fetchRosters, fetchBaseInfo } from '@/api/huddles'
import {
    Huddle,
    HuddlesRoster,
    BaseGoals,
    HuddlesBaseInfo,
    CustomerHighlight,
} from '@/entities/huddles'
import Carousel from 'primevue/carousel'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import HuddlesLoading from '@/mobile/src/views/appV4/huddles/HuddlesLoading.vue'
import HuddlesError from '@/mobile/src/views/appV4/huddles/HuddlesError.vue'
import HuddlesPromotion from '@/mobile/src/views/appV4/huddles/HuddlesPromotion.vue'
import HuddleDetails from '@/mobile/src/views/appV4/huddles/HuddleDetails.vue'
import HuddlesCustomerHighlights from '@/mobile/src/views/appV4/huddles/HuddlesCustomerHighlights.vue'
import HuddlesOverviewStats from '@/mobile/src/views/appV4/huddles/HuddlesOverviewStats.vue'
import HuddlesDatePaginator from '@/mobile/src/views/appV4/huddles/HuddlesDatePaginator.vue'

const store = useStore()
const toast = useToast()

const loadingEntirePage = ref(true)
const loadingHuddleList = ref(false)
const showError = ref(false)
const selectedHuddleId = ref()
const showHuddleModal = ref(false)
const selectedDate = ref('')
const huddlesRosters: Ref<HuddlesRoster[]> = ref([])
const promotionMessage = ref('')
const base: Ref<string> = ref('')
const baseGoals: Ref<BaseGoals> = ref({
    cxscore: undefined,
    cxscore_goal: undefined,
    cxscore_goal_range: undefined,
    last_month_sales_per_head: 0,
    sales_per_head_goal: 0,
    last_month: '',
})
const customerHighlights: Ref<CustomerHighlight[]> = ref([])

onMounted(async () => {
    loadingEntirePage.value = true

    await fetchBaseInfo()
        .then((response) => {
            const baseInfo: HuddlesBaseInfo = response.data.result
            promotionMessage.value = baseInfo.promotion ?? undefined
            baseGoals.value = baseInfo.goals
            customerHighlights.value = baseInfo.customer_highlights ?? []
            base.value = baseInfo.base ?? ''
        })
        .catch(() => {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Error fetching base info',
                life: 2000,
            })
            showError.value = true
        })

    await fetchRosters()
        .then((response) => {
            huddlesRosters.value = response.data.result.rosters
            selectedDate.value = new Date().toISOString().split('T')[0]
        })
        .catch(() => {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Error loading rosters',
                life: 2000,
            })
            showError.value = true
        })

    loadingEntirePage.value = false
})

const openHuddleModal = (huddleId: number) => {
    selectedHuddleId.value = huddleId
    showHuddleModal.value = true
}

const selectedDatesHuddles: ComputedRef<Huddle[]> = computed(() => {
    return (
        huddlesRosters.value.find((huddle) => {
            return huddle.date === selectedDate.value
        })?.huddles ?? []
    )
})

const getSelectedHuddle: ComputedRef<Huddle | undefined> = computed(() => {
    return selectedDatesHuddles.value.find(
        (huddle) => huddle.id === selectedHuddleId.value
    )
})

const getCarouselDetails = computed(() => {
    const selectedHuddleDetailindex = selectedDatesHuddles.value.findIndex(
        (huddle) => huddle.id === selectedHuddleId.value
    )

    if (selectedHuddleDetailindex < 1) {
        return selectedDatesHuddles.value
    }

    const beforeIndex = selectedDatesHuddles.value.slice(
        0,
        selectedHuddleDetailindex
    )
    const afterIndex = selectedDatesHuddles.value.slice(
        selectedHuddleDetailindex
    )

    return [...afterIndex, ...beforeIndex]
})

const companyLogo: ComputedRef<string> = computed(() => {
    if (store.getters['details'].company?.logo) {
        return getCDNUrl(store.getters['details'].company?.logo)
    }
    return ''
})

const canPaginate = (direction: 'previous' | 'next'): boolean => {
    const nextDay = new Date(selectedDate.value)
    nextDay.setDate(nextDay.getDate() - (direction === 'previous' ? 1 : -1))

    const nextDayHuddle = huddlesRosters.value.find((huddle) => {
        return huddle.date === nextDay.toISOString().split('T')[0]
    })
    return !!nextDayHuddle
}

const onPaginationChange = (direction: 'previous' | 'next') => {
    pendoTrackEvent(`huddles-paginate-to-${direction}-day`)

    const nextDay = new Date(selectedDate.value)
    nextDay.setDate(nextDay.getDate() - (direction === 'previous' ? 1 : -1))
    selectedDate.value = nextDay.toISOString().split('T')[0]
}

const getFlightKey = (huddle: Huddle): string => {
    return huddle.flight_number + huddle.depart_station + huddle.arrival_station
}
</script>

<style lang="less" scoped>
@import '@/styles/prime-vue/variables.less';
@import '@/styles/rain/variables.less';
@import '@/styles/huddles.less';
@import '~@/styles/variables.less';

@gap: 32px;
@sidePadding: 42px;

.page-wrapper {
    display: flex;
    flex-direction: column;
}

#huddles-container {
    padding: @gap @sidePadding;
    display: flex;
    gap: @gap;
    scroll-behavior: smooth;
}

#huddles-error-container {
    padding: 0 @sidePadding @gap @sidePadding;
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
    gap: @gap;

    &--left {
        width: 300px;
        height: fit-content;
    }

    &--right {
        flex: 1;
    }
}

#company-logo {
    max-width: 180px;
    margin: auto;
}

.huddles-card {
    &--details {
        padding: 0;
    }
}

.base-display {
    padding: @spacing-md @spacing-lg;
    border: 1px solid var(--p-gray-200);
    box-shadow: @shadow-xs;
    border-radius: @radius-md;
    color: var(--p-gray-400);
    font-size: @fontSize-base;
    line-height: @lineHeight-base;
}
</style>

<style lang="less">
@import '@/styles/prime-vue/colours.less';

.huddle-details-modal {
    width: 80%;

    .close-modal-button {
        color: var(--p-gray-400);
        background: none;
        border: none;
        position: absolute;
        right: 0;
        width: 20px;
        height: 20px;
        padding: 8px;
        z-index: 1000;
    }
}
.p-carousel-indicator-button {
    height: 4px;
    border-radius: 20px;
}
</style>
