import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { LoadState } from '@/common/interfaces/loadstate'
import { mutateObject } from '@/utils/object'

export interface SurveyState {
    datasources: string[]
    surveysIds: string[]
    surveyId: string
    surveys: { [surveyId: string]: any }
    unsavedChanges: boolean
    unsavedChangesAlert: boolean
    loadState: {
        datasources: LoadState
        overview: LoadState
    }
}

export const state: SurveyState = {
    datasources: [],
    surveysIds: [],
    surveyId: '',
    surveys: {},
    unsavedChanges: false,
    unsavedChangesAlert: false,
    loadState: {
        datasources: LoadState.UNSET,
        overview: LoadState.UNSET,
    },
}

const actions: ActionTree<SurveyState, any> = {
    async updateSurvey({ commit }, survey) {
        commit('updateSurvey', survey)
    },
    async addNewSurvey({ commit }) {
        commit('addSurvey', {})
    },
    async loadSurveys(params, surveys) {
        const { commit } = params
        commit('setSurveys', surveys)
    },
}

const mutations: MutationTree<SurveyState> = {
    addSurvey(state, survey) {
        state.surveys = mutateObject(state.surveys, survey.id, survey)
        state.surveysIds.push(survey.id)
    },
    setSurveyId(state, id) {
        state.surveyId = id
    },
    removeSurvey(state, id: string) {
        const { [id]: omit, ...keep } = state.surveys
        state.surveys = keep
        state.surveysIds = state.surveysIds.filter((curId) => curId !== id)
    },
    setSurveys(state, surveys) {
        state.surveys = surveys.reduce((all, survey) => {
            all[survey.id] = survey
            state.surveysIds.push(survey.id)
            return all
        }, {})
    },
    setDatasources(state, data) {
        state.datasources = data
    },
    setLoadState(state, data) {
        state.loadState = {
            ...state.loadState,
            ...data,
        }
    },
    setSurveyField(state, { field, value }) {
        const survey = state.surveys[state.surveyId]
        if (!survey) {
            return
        }
        state.surveys = {
            ...state.surveys,
            [state.surveyId]: {
                ...survey,
                [field]: value,
            },
        }
    },
    updateSurvey(state, data) {
        const survey = state.surveys[data.id]
        if (!survey) {
            return
        }
        state.surveys = {
            ...state.surveys,
            [survey.id]: {
                ...survey,
                ...data,
                id: survey.id,
            },
        }
    },
    setUnsavedChanges(state, data) {
        state.unsavedChanges = data
    },
    setUnsavedChangesAlert(state, data) {
        state.unsavedChangesAlert = data
    },
}

const getters: GetterTree<SurveyState, any> = {
    getSurveys: (state) => state.surveysIds.map((id) => state.surveys[id]),
    getSurveyId: ({ surveyId }) => surveyId,
    getSurvey: ({ surveyId, surveys }) => surveys[surveyId],
    getSurveyById:
        ({ surveys }) =>
        (id) =>
            surveys[id],
    getDatasources: ({ datasources }) => datasources,
    getUnsavedChanges: ({ unsavedChanges }) => unsavedChanges,
    getUnsavedChangesAlert: ({ unsavedChangesAlert }) => unsavedChangesAlert,
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
    getters,
}
