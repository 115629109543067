import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d25bf65"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "custom-data-label"
}
const _hoisted_2 = {
  key: 1,
  class: "custom-data-label"
}
const _hoisted_3 = { class: "custom-data-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponseCircle = _resolveComponent("ResponseCircle")!
  const _component_FeedbackBaseCard = _resolveComponent("FeedbackBaseCard")!

  return (_openBlock(), _createBlock(_component_FeedbackBaseCard, {
    timestamp: Number(_ctx.response.responded),
    author: _ctx.responseAuthor,
    text: _ctx.responseComment,
    "original-comment": _ctx.originalComment,
    "text-italic": _ctx.isNoCommentResponse,
    "is-starred": _ctx.response.isStarred,
    "comment-count": _ctx.commentCount,
    "comment-count-class": 
            _ctx.getMetricStyle(_ctx.response.question_type, Number(_ctx.response.answer))
        ,
    "show-star": _ctx.detailedView,
    "detailed-view": _ctx.detailedView,
    "emoji-view": _ctx.detailedView,
    "emoji-reactions": _ctx.emojiReactions,
    onOnReact: _ctx.onReact,
    onToggleStarred: _ctx.onToggleStarred,
    onViewDetail: _ctx.viewDetail
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_ResponseCircle, {
        score: Number(_ctx.response.answer),
        variation: _ctx.response.question_type
      }, null, 8, ["score", "variation"])
    ]),
    title: _withCtx(() => [
      (_ctx.responseTopic)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.responseTopic), 1)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(" No Topic Provided ")
          ], 64))
    ]),
    extraDetails: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customDataSet, (customDataField, customDataIdx) => {
        return (_openBlock(), _createElementBlock("div", {
          key: customDataIdx,
          class: "custom-data-container"
        }, [
          (_ctx.isAiQuestion(customDataField.key))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(customDataField.question_text), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.friendlyFieldName(customDataField.key)), 1)),
          _createElementVNode("div", _hoisted_3, _toDisplayString(customDataField.value), 1)
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["timestamp", "author", "text", "original-comment", "text-italic", "is-starred", "comment-count", "comment-count-class", "show-star", "detailed-view", "emoji-view", "emoji-reactions", "onOnReact", "onToggleStarred", "onViewDetail"]))
}