export interface TeamScoreboardEntity {
    parent?: {
        field: string
        group: string
    }
    selected?: {
        field: string
        role: string
    }
    parentField: string
    parentFieldLabel: string
    parentGroups: string[]
    field: string
    fieldLabel: string
    groups: string[]
    scorecardField: string
    scorecardValues: string[]
    role: string
    tsGroupSummary?: TsGroupSummaryEntity
}

export interface TsTeamGroupEntity {
    groupName: string
    fieldMapping: string
    rankWithin: string
    role?: string
    customSelectionName?: string
    rankWithinName?: string // for extraFilter, filter child data with parent name
    childLevel?: string
}

export interface TsGroupRepeatVisitsEntity {
    field: string
    group: string
    repeatVisitDelta: number
    repeatVisitPercentage: number
    retention: {
        retention: number
        n: number
        s: number
        e: number
    }
    previousRetention: {
        retention: number
        n: number
        s: number
        e: number
    }
    tenure: {
        tenure: number
        active: number
        churned: number
    }
    previousTenure: {
        tenure: number
        active: number
        churned: number
    }
}

export interface TsGroupRepeatVisitsSummaryEntity {
    repeatVisitPercentageDelta: number
    repeatVisitPercentage: number
    customerRetention: number
    customerRetentionDelta: number
    customerTenure: number
    customerTenureDelta: number
    tsgroups: TsGroupRepeatVisitsEntity[]
    graph: {
        repeatVisitPercentage: number[]
        customerRetention: number[]
        customerTenure: number[]
    }
}

export interface TsGroupSummaryEntity {
    tsgroups: TsGroupEntity[]
    aggregateScore: number
    minNPS: number
    lowerThirdNPS: number
    upperThirdNPS: number
    maxNPS: number
    averageNPS: number
    minResponded: number
    lowerThirdResponded: number
    upperThirdResponded: number
    maxResponded: number
    maxRespondedPromoterDetractor: number
    isFiveScore: boolean
    isLeafGroup: boolean
    scorecardName: string
}

export interface TsGroupEntity {
    responded: number
    NPS: number
    scoreDelta: number
    previousNPS: number
    previousResponded: number
    field: string
    avatar?: string
    user_id?: number
    group: string
    respondedPromoter: number
    respondedDetractor: number
    answersum?: number
    topicKey?: string
}

export interface TsNpsSummaryEntity {
    nps: number
    change: number
    total: number
    scorecardQuestionTotal: number
    promoter: number
    passive: number
    detractor: number
    rank?: number
    rankFieldLabel?: string
    isFiveScore?: boolean
    graph?: any
    success?: boolean
}

export interface TsFilterEntity {
    type: 'search' | 'grid'
    operator: 'equals' | 'contains'
    filters: string[]
}

export interface TsHasNoScorecardResponse {
    has_no_scorecard_responses: boolean
}

export const TsFilterTypes = {
    search: 'search',
    grid: 'grid',
}

export const TsFilterOperators = {
    equals: 'equals',
    contains: 'contains',
}
