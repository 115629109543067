<template>
    <div class="huddle-sales">
        <div class="top-selling-items-header">
            <FontAwesomeIcon :icon="faCircleDollar" size="xl" />
            <Typography variant="subtitle1">Top Selling Items</Typography>
        </div>

        <div v-if="summaryView" class="view--summary">
            <HuddleSalesItem
                v-for="(salesItem, index) in sortedTopSellers.slice(0, 3)"
                :key="index"
                :item-name="salesItem.item"
                :item-count="salesItem.count"
                type="summary"
            />
        </div>
        <div v-else class="view--full">
            <!--            {{ sortedTopSellers }}-->
            <HuddleSalesItem
                v-for="(salesItem, index) in sortedTopSellers"
                :key="index"
                :item-name="salesItem.item"
                :item-count="salesItem.count"
                type="full"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, type ComputedRef } from 'vue'
import { SalesItem } from '@/entities/huddles'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCircleDollar } from 'fontawesome/pro-regular-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'
import HuddleSalesItem from '@/mobile/src/views/appV4/huddles/HuddleSalesItem.vue'

interface Props {
    topSellingItems: SalesItem[]
    summaryView: boolean
}
const props = withDefaults(defineProps<Props>(), {
    summaryView: false,
})

const sortedTopSellers: ComputedRef<SalesItem[]> = computed(() => {
    return [...props.topSellingItems].sort((a, b) => {
        return a.count > b.count ? -1 : 1
    })
})
</script>

<style lang="less" scoped>
@import '@/styles/prime-vue/variables.less';
@import '@/styles/rain/variables.less';

.huddle-sales {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.top-selling-items-header {
    display: flex;
    gap: @spacing-sm;
    color: var(--p-gray-600);
    align-items: center;
    margin-bottom: @spacing-md;
}

.view {
    &--summary {
        display: flex;
        flex-grow: 2;
        align-items: center;
    }

    &--full {
        display: flex;
        flex-direction: column;
        gap: @spacing-md;
    }
}
</style>
