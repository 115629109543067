<template>
    <div class="date-paginator">
        <Button
            class="pagination pagination--button"
            :disabled="!hasPrev || loading"
            @click="emit('onPaginate', 'previous')"
        >
            <i class="pi pi-angle-left" />
            <Typography variant="subtitle2">Previous day</Typography>
        </Button>
        <div class="pagination pagination--date-display">
            <i class="pi pi-calendar" />
            <Typography variant="subtitle2">{{ formattedDate }}</Typography>
        </div>
        <Button
            class="pagination pagination--button"
            :disabled="!hasNext || loading"
            @click="emit('onPaginate', 'next')"
        >
            <Typography variant="subtitle2">Next day</Typography>
            <i class="pi pi pi-angle-right" />
        </Button>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'
import Button from 'primevue/button'
import Typography from '@/components/Rain/Typography/Typography.vue'

interface Props {
    loading: boolean
    hasNext: boolean
    hasPrev: boolean
    selectedDate: string // Format: ISO 8601 YYYY-MM-DD
}
const props = withDefaults(defineProps<Props>(), {
    loading: false,
})

const store = useStore()
const emit = defineEmits(['onPaginate'])

const formattedDate = computed(() =>
    moment(props.selectedDate).format('dddd D MMMM')
)
</script>

<style lang="less" scoped>
@import '~@/styles/prime-vue/variables.less';
@import '~@/styles/rain/colour.less';

.date-paginator {
    display: flex;
    gap: @spacing-2xl;
}

.pagination {
    border: 1px solid var(--p-gray-300);
    padding: @spacing-md @spacing-lg;
    box-shadow: @shadow-xs;
    background: @white;
    color: var(--p-gray-700);
    border-radius: @radius-md;

    &--date-display {
        display: flex;
        flex: 2;
        gap: @spacing-sm;
        justify-content: center;
        align-items: center;
    }
}
</style>
