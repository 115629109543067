import { IPerson } from '@/entities/person'
import { EmojiReactionDetails } from '@/entities/chat'
import { MessageTypeLabel } from '@/mobile/src/types/messageTypes'

export interface IResponse {
    id: number
    answer: string
    comment: string
    original_comment?: string
    customFields: CustomField[]
    badges?: IBadge[]
    errors?: {
        message: string
    }
    question_type?: string
    responded?: string
    isStarred?: boolean
    automation_count?: number
    note_count?: number
    response_count?: number
    chat_count?: number
    notices_count?: number
    chat_outgoing?: number
    previous_answer?: number
    anom?: boolean
    person?: IPerson
    person_name?: string
    person_email?: string
    dontcontact?: number
    emojis?: EmojiReactionDetails[]
    type:
        | 'chat'
        | 'note'
        | 'question'
        | 'message'
        | MessageTypeLabel.internalNote
        | MessageTypeLabel.workflow
        | MessageTypeLabel.emailReply
}

export interface IGetResponseOptions {
    pagesize?: number
    offset?: number
    freetext?: string
    customFields?: string[]
    withtotal?: boolean
    applyScorecardLockedFilters?: number
    applyScorecardTemplateFilter?: number
    question_type?: string
    selectedScorecard?: number
}

export interface CustomField {
    key: string
    value?: string
}

export interface IBadge {
    badge: string
    badge_id: string
    label: string
    type: string
    topic_name?: string
}

export type Answer = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export interface IConvertedContact {
    metrics?: string
    type?: string
    description: string
    total: number
    locations?: Array<IConvertedContact>
    location: string
    topics?: Array<string>
    topic: string
}

export enum IReturningContactsCardTypes {
    convertedDetractors = 'convertedDetractors',
    convertedPromoters = 'convertedPromoters',
}

export interface IReturningContactsCard {
    description: string
    metrics?: string
    total: number
    totalImage?: string
    type: IReturningContactsCardTypes
    underThreshold: boolean
    first?: IConvertedContact
    second?: IConvertedContact
    third?: IConvertedContact
}

export interface IConvertedContactsResult {
    readonly number: number
    readonly emptyStateMessage: string
}

export const noCommentResponse = 'No comment'
export enum CustomerInfo {
    anon = 'anon',
}
