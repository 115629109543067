<template>
    <div class="huddles-card customer-highlights">
        <div class="customer-highlights-header">
            <FontAwesomeIcon size="lg" :icon="faCommentAltLines" />
            Customer Highlights
        </div>
        <Carousel
            :value="highlights"
            :num-visible="1"
            :num-scroll="1"
            :show-navigators="false"
            circular
            :pt="{ indicatorList: { style: { padding: '20px 0 0 0' } } }"
        >
            <template #item="slotProps">
                <div class="highlight-item">
                    <div class="flight-details">
                        <Typography variant="caption" class="flight-info">
                            {{ slotProps.data.flight_number }}
                        </Typography>
                        <Typography variant="caption" class="flight-info">
                            {{ slotProps.data.flight_key }}
                        </Typography>
                        <Typography variant="caption" class="flight-info">
                            {{ timeSince(slotProps.data.responded) }}
                        </Typography>
                    </div>
                    <span class="flight-feedback">
                        <Typography
                            variant="caption"
                            class="flight-feedback-text"
                            @click="navigateToFeedback(slotProps.data.id)"
                        >
                            {{ slotProps.data.comment }}
                        </Typography>
                    </span>
                </div>
            </template>
        </Carousel>
    </div>
</template>

<script lang="ts" setup>
import Typography from '@/components/Rain/Typography/Typography.vue'
import Carousel from 'primevue/carousel'

import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { timeSince } from '@/utils/time'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faCommentAltLines } from 'fontawesome/pro-regular-svg-icons'
import { OFFLINE_FEATURE_DISABLE_MESSAGE } from '@/utils/string'
import { CustomerHighlight } from '@/entities/huddles'

const router = useRouter()
const store = useStore()

interface Props {
    highlights: CustomerHighlight[]
}
const props = defineProps<Props>()

const hasNetworkConnection = computed(() => store.getters.hasNetworkConnection)

const navigateToFeedback = (responseId: number) => {
    if (!hasNetworkConnection.value) {
        alert(OFFLINE_FEATURE_DISABLE_MESSAGE)
        return
    }
    router.push({
        name: 'feedbackdetail',
        params: { feedbackid: responseId.toString() },
    })
}
</script>

<style lang="less">
@import '@/styles/huddles.less';
@import '@/styles/prime-vue/variables.less';
@import '@/styles/rain/variables.less';

.customer-highlights {
    display: flex;
    flex-direction: column;
    gap: @spacing-2xl;
}

.flight-feedback {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 43px; /* fallback if webpack is not supported */
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.flight-feedback-text {
    border-left: 2px solid rgb(0, 214, 143);
    color: var(--p-gray-900);
    padding-left: 10px;
    font-size: @fontSize-sm;
    font-weight: @fontWeight-regular;
    line-height: @lineHeight-sm;
    letter-spacing: @letterSpacing-lg;
}

.customer-highlights-header {
    color: var(--p-gray-600);
    font-size: @fontSize-md;
    font-weight: @fontWeight-medium;
    line-height: @lineHeight-md;
    letter-spacing: @letterSpacing-base;
    display: flex;
    align-items: center;
    gap: @spacing-sm;
}

.highlight-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.flight-details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flight-info {
        text-align: left;
        color: var(--p-gray-600);
    }
}

.p-carousel-indicator-button {
    height: 4px;
    border-radius: 20px;
}
</style>
